import { DefaultTexts } from '@aws-amplify/ui';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Alert } from '@mui/material';
import { Amplify, I18n } from 'aws-amplify';
import axios from 'axios';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CustomAuthenticator from './components/CustomAuthenticator';
import { axiosRequestInterceptor } from './interceptors/auth';
import Layout from './layout';
import Home from './pages/Home';
import InvoiceDetails from './pages/InvoiceDetails';
import NotFound from './pages/NotFound';
import PersonalInfo from './pages/PersonalInfo';
import { useAppSelector } from './store/reducer/Hooks';
import { RootState } from './store/Store';

I18n.putVocabulariesForLanguage('en', {
    Username: 'Email',
    'Enter your Username': 'Email',
    [DefaultTexts.ENTER_USERNAME]: 'Email',
    [DefaultTexts.ENTER_PASSWORD]: 'Password',
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: 'invoices/:uuid',
                element: <InvoiceDetails />,
            },
            {
                path: 'my-profile',
                element: <PersonalInfo />,
            },
            {
                path: 'not-found',
                element: <NotFound />,
            },
        ],
    },
]);

function App() {
    const uiConfig = useAppSelector((state: RootState) => state.persistedSellerReducer).uiConfig;

    useEffect(() => {
        if (uiConfig != null) {
            Amplify.configure({
                aws_user_pools_id: uiConfig.userPoolId,
                aws_cognito_region: process.env.REACT_APP_USER_POOL_REGION,
                aws_user_pools_web_client_id: uiConfig.clientId,
            });
        }

        axios.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e));
    }, [uiConfig]);

    if (uiConfig == null) {
        return (
            <Alert sx={{ mt: 1 }} severity='error'>
                The payment portal is not setup correctly, please contact Simfuni.
            </Alert>
        );
    }

    return (
        <Authenticator.Provider>
            <CustomAuthenticator>
                <RouterProvider router={router} />
            </CustomAuthenticator>
        </Authenticator.Provider>
    );
}

export default App;
