import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchInvoice,
    fetchLoan,
    fetchTermPaymentMethods,
    InvoiceStatus,
    InvoiceSummary,
    MigrationStatus,
} from '../../apis/invoices';
import { fetchCurrentPaymentMethod } from '../../apis/paymentMethodApi';
import MigratedInvoiceAlert from '../../components/MigratedInvoiceAlert';
import PageLoading from '../../components/PageLoading';
import { isSuccess, simpleStoreFetch } from '../../hooks/useFetch';
import { RootState } from '../../store/Store';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setAvailablePaymentMethodsState, setInvoice, setPaymentMethodState } from '../../store/reducer/InvoiceReducer';
import { setLoanState } from '../../store/reducer/LoanReducer';
import { isInFuture } from '../../util/dateUtils';
import CancellationScheduledAlert from './CancellationScheduledAlert';
import CancelledAlert from './CancelledAlert';
import Details from './Details';
import OverdueAlert from './OnDemandPayment/OverdueAlert';
import PaymentRequiredAlert from './OnDemandPayment/PaymentRequiredAlert';
import PendingCancellationAlert from './PendingCancellationAlert';

export default () => {
    const navigate = useNavigate();
    const { uuid } = useParams<{ uuid?: string }>();
    const { value: invoice, paymentMethodState } = useAppSelector((state: RootState) => state.persistedInvoiceReducer);
    const { value: loanState } = useAppSelector((state: RootState) => state.LoanReducer);
    const [loading, setLoading] = useState(invoice == null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (invoice?.uuid == null || uuid == null) {
            return;
        }

        if (!isSuccess(loanState) || loanState.value.invoiceIdentifier !== uuid) {
            simpleStoreFetch({ dispatch, setState: setLoanState, fetch: () => fetchLoan(uuid) });
        }

        if (!isSuccess(paymentMethodState) || paymentMethodState.value.invoiceIdentifier !== uuid) {
            simpleStoreFetch({
                dispatch,
                setState: setPaymentMethodState,
                fetch: () => fetchCurrentPaymentMethod(uuid),
            });

            simpleStoreFetch({
                dispatch,
                setState: setAvailablePaymentMethodsState,
                fetch: () => fetchTermPaymentMethods(uuid),
            });
        }
    }, [invoice]);

    useEffect(() => {
        if (invoice?.uuid !== uuid && uuid != null && !loading) {
            setLoading(true);
            fetchInvoice(uuid)
                .then((invoiceSummary) => {
                    dispatch(setInvoice(invoiceSummary));
                })
                .catch(() => {
                    navigate('/not-found');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [uuid]);

    if (!uuid || loading || invoice?.uuid == null) {
        return <PageLoading />;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography variant='h3' component='h1'>
                Insurance details
            </Typography>
            {getAlerts(invoice).map((alert) => (
                <Box key={alert.key}>{alert.component}</Box>
            ))}
            <Details />
        </Box>
    );
};

type Alert = {
    component: JSX.Element;
    key: string;
};
const getAlerts = (invoice: InvoiceSummary): Alert[] => {
    const alerts = [];

    if (isInFuture(invoice.cancellationDate)) {
        alerts.push({
            component: <CancellationScheduledAlert invoice={invoice} />,
            key: 'cancellation-scheduled-alert',
        });
    }

    if (invoice.migrationStatus === MigrationStatus.PARTIAL) {
        alerts.push({ component: <MigratedInvoiceAlert />, key: 'migrated-invoice-alert' });
    }

    switch (invoice.status) {
        case InvoiceStatus.ACTIVE_PENDING_ACCEPTANCE:
            alerts.push({ component: <PaymentRequiredAlert invoice={invoice} />, key: 'payment-required-alert' });
            break;

        case InvoiceStatus.PENDING_CANCELLATION:
            alerts.push({ component: <PendingCancellationAlert />, key: 'pending-cancellation-alert' });
            break;

        case InvoiceStatus.CANCELLED:
            alerts.push({ component: <CancelledAlert invoice={invoice} />, key: 'cancelled-alert' });
            break;

        case InvoiceStatus.OVERDUE:
            alerts.push({ component: <OverdueAlert invoice={invoice} />, key: 'overdue-alert' });
            break;
    }

    return alerts;
};
