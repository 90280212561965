import { PaletteColor } from '@mui/material';
import axios from 'axios';

export type UIConfig = {
    version: string;
    clientId: string;
    userPoolId: string;
    logoUrl?: string;
    origins?: Origins;
    colors?: {
        primary?: PaletteColor;
        secondary?: PaletteColor;
    };
    displayName?: string;
    favIconUrl?: string;
};

export enum AppPortalType {
    CLIENT = 'CLIENT',
    CHECKOUT = 'CHECKOUT',
}

export type Origins = { [key in AppPortalType]?: string };

export const getSellerUIConfig = async (): Promise<UIConfig | undefined> => {
    return await axios.get(`${process.env.REACT_APP_CHECKOUT_PORTAL_HOST}/seller/_config/ui`).then(({ data }) => data);
};
