import moment from 'moment';
import { InvoiceSummary } from '../apis/invoices';
import { Chip } from '@mui/material';
import { DATE_COMPACT } from '../util/dateUtils';

export default ({ invoice }: { invoice: InvoiceSummary }) => {
    const endDates = invoice.portfolio.policies.map((policy) => moment(policy.currentPolicyVersion.endDate));
    return (
        <Chip
            sx={{ alignSelf: 'flex-start', cursor: 'inherit' }}
            color='primary'
            variant='outlined'
            label={`Paid until ${moment.min(endDates).format(DATE_COMPACT)}`}
        />
    );
};
